<template>
  <div
    class="af-modal-card"
    :class="brand"
    :style='{"width":"100vw","maxWidth":"700px"}'
  >
    <div class="modal-card__content" :style='{"padding":"16px 0"}'>
      <p class="has-text-weight-bold mb-5" :style='{"fontSize":"24px"}'>
        {{ $t("af:mortgage_pre_onboarding.modal.title") }}
      </p>

      <div :style='{"maxHeight":"50vh","overflowY":"scroll"}'>
        <application-template
          template="MORTGAGE_PRE_ONBOARDING"
        ></application-template>
      </div>

      <div class="mt-5 has-text-centered">
        <b-button type="is-primary" @click="$emit('close')">
          {{ $t("af:mortgage_pre_onboarding.modal.continue") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UI_CONFIGS } from "@/utils/const";

export default {
  name: "MortgagePreOnboardingModal",
  components: {
    ApplicationTemplate: () => import("@/components/ApplicationTemplate.vue"),
  },
  data() {
    return {
      accConfigs: [],
      userId: null,
    };
  },
  computed: {
    ...mapGetters({
      templateRender: "template/get",
    }),
    brand() {
      return this.$store.getters["config/getAutoloadConfigValue"](
        UI_CONFIGS.APPEARANCE
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.modal-card {
  background: white;
  border: 1px solid $grey-lightest;
  border-radius: 24px;
  position: relative;
  padding: 1rem;
  width: 100%;
  max-height: 90vh;

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width: 769px) {
  .modal-card {
    padding: 48px;
  }

  .statements {
    max-height: 12.5rem !important;
  }
}
</style>
