<template>
  <div class="onboarding-container">
    <div class="onboarding-header">
      <!--      <div class="has-text-right">-->
      <!--        <span-->
      <!--          class="cursor-pointer"-->
      <!--          @click="skip"-->
      <!--          id="mortgageOnboardingSkip"-->
      <!--          >{{ $t("af:mortgage_onboarding.button.skip") }}</span-->
      <!--        >-->
      <!--      </div>-->

      <template v-if="!isBrand(CUSTOM_APPEARANCE.MBH)">
        <transition name="fade" mode="out-in">
          <div class="aspect-ratio-wrapper" :key="currentStepIndex">
            <div
              class="onboarding-header__image"
              :style="{
                backgroundImage: `url(${currentOnboardingStep.imageUrl})`,
              }"
            />
          </div>
        </transition>
      </template>

      <template v-else>
        <div class="mt-3">
          <transition name="fade" mode="out-in">
            <img
              :key="currentStepIndex"
              :src="currentOnboardingStep.imageUrl"
              class="onboarding-step-image"
              alt="Onboarding image"
            />
          </transition>
        </div>
      </template>

      <div class="step-dots mt-4">
        <div
          class="dot"
          v-for="index in totalOnboardingSteps"
          :key="index"
          :class="{ 'dot--active': index - 1 === currentStepIndex }"
        ></div>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <div class="onboarding-description mt-4" :key="currentStepIndex">
        <p class="is-size-3 has-text-weight-bold mb-2">
          {{ currentOnboardingStep.title }}
        </p>

        <p>
          {{ currentOnboardingStep.description }}
        </p>
        <br />
      </div>
    </transition>

    <div class="onboarding-actions">
      <b-button
        v-if="hasPrevious"
        type="is-grey-lighter"
        icon-pack="fas"
        icon-right="angle-left"
        @click="previous"
        id="mortgageOnboardingPreviousStep"
      ></b-button>
      <b-button
        v-if="hasNext"
        class="next-btn"
        type="is-blue"
        @click="next"
        id="mortgageOnboardingNextStep"
        >{{ $t("af:mortgage_onboarding.button.next") }}</b-button
      >
      <b-button
        v-else
        class="next-btn"
        type="is-blue"
        @click="skip"
        id="mortgageOnboardingFinish"
        >{{ $t("af:mortgage_onboarding.button.to_preliminary") }}</b-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { isBrand } from "@/utils/util";
import { APPLICATION_MODE, CUSTOM_APPEARANCE } from "@/utils/const";
import MortgagePreOnboardingModal from "@/components/MortgagePreOnboardingModal.vue";

export default {
  name: "MortgageOnboarding",
  title: "af:page.mortgage_onboarding.title",
  data() {
    return {
      currentStepIndex: 0,
      onboardingSteps: [],
    };
  },
  computed: {
    CUSTOM_APPEARANCE() {
      return CUSTOM_APPEARANCE;
    },
    ...mapGetters({
      fileGetter: "drawer/getFile",
    }),
    totalOnboardingSteps() {
      return this.onboardingSteps.length;
    },
    hasNext() {
      return this.onboardingSteps.length > this.currentStepIndex + 1;
    },
    hasPrevious() {
      return this.currentStepIndex > 0;
    },
    currentOnboardingStep() {
      return this.onboardingSteps[this.currentStepIndex];
    },
  },
  async created() {
    await this.$store.dispatch("profile/fetch");
    if (!this.$store.getters["profile/isInMortgageState"]("REGISTERED")) {
      await this.$router.push(
        this.$store.getters["profile/getMortgageBackPath"]
      );
      return;
    }

    this.$buefy.modal.open({
      parent: this,
      component: MortgagePreOnboardingModal,
      hasModalCard: true,
      trapFocus: true,
      canCancel: true,
    });

    await this.$store.dispatch("onboarding/fetchOnboarding", "MORTGAGE");
    const onboardingSteps = this.$store.getters["onboarding/onboardingPages"];

    try {
      const promises = [];
      onboardingSteps.forEach((step) => {
        if (step.image) {
          promises.push(this.fetchFile(step.image));
        }
      });
      await Promise.all(promises);

      onboardingSteps.forEach((step) => {
        if (step.image) {
          step.imageUrl = URL.createObjectURL(this.fileGetter(step.image));
        }
      });
    } catch (e) {
      console.error(e);
      console.error("Onboarding images failed!");
    }

    onboardingSteps.sort((a, b) => a.rowIndex - b.rowIndex);

    this.onboardingSteps = onboardingSteps;
  },
  methods: {
    isBrand,
    ...mapActions({
      fetchFile: "drawer/download",
    }),
    next() {
      this.currentStepIndex++;
    },
    previous() {
      this.currentStepIndex--;
    },
    async skip() {
      await this.$store.dispatch("profile/updateMortgageStatus", {
        status: "ONBOARDED",
        result: "SUCCESS",
      });
      await this.$store.dispatch(
        "profile/updateApplicationMode",
        APPLICATION_MODE.MORTGAGE
      );

      await this.$router.push("/mortgage/preliminary-assessment");
    },
    docUrl(id) {
      return `/file/public/${id}`;
    },
  },
};
</script>

<style scoped lang="scss">
.onboarding-container {
  height: 100vh;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0 auto;
  padding: 20px;
  border-radius: 24px;
  background: white;

  .onboarding-header {
    //flex-grow: 1;
    max-height: 400px;
    display: flex;
    flex-direction: column;

    .aspect-ratio-wrapper {
      position: relative;
      width: 90%;
      max-height: 350px;
      max-width: 350px;
      margin: 0 auto;

      &::after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
    }

    &__image {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 10000px;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .step-dots {
      display: flex;
      width: 100%;
      justify-content: center;

      .dot {
        height: 8px;
        width: 8px;
        border: 1px solid #adb7c4;
        border-radius: 8px;
        margin: 0 4px;

        &--active {
          border: 1px solid #1072fc;
          background: #1072fc;
        }
      }
    }
  }

  .onboarding-description {
    flex-grow: 1;
  }

  .onboarding-actions {
    margin-top: auto;
    // margin-top: 2rem;
    display: flex;
    justify-content: space-between;

    .next-btn {
      margin-left: auto;
    }
  }
}

.mbh {
  .onboarding-header {
    max-height: initial !important;
  }

  .onboarding-description {
    text-align: center;
  }

  .onboarding-step-image {
    max-height: 350px;
  }
}

@media only screen and (min-width: 769px) {
  .onboarding-container {
    height: initial;
    min-height: 60vh;
    box-shadow: 0 5px 30px rgba(48, 52, 62, 0.1);
    padding: 48px;
    position: absolute;
    transform: translateX(-50%);
    top: 10%;
    left: 50%;
    width: 50%;
    max-width: 600px;
  }
}
</style>
